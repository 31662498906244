import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { JOrder } from "./jomla/orderSlice";
const posApi = factory.get("pos");
const initialState = {
  searchBody: {
    search: "",
    rep_id: null,
    group_id: null,
    startDate: null,
    endDate: null,
    custom_discounts: 0,
    has_j_balance: null,
    is_approved: "1",
    min_order_amount: "",
    province_id: null,
    pos_type_id: null,
    pos_field_id: null,
  },
  pos: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      user_id: "",
      password: "",
      email: "",
      name: "",
      owner: "",
      phone: "",
      group_id: null,
      can_transfer: false,
      province_id: null,
      city_id: null,
      wage: "",
      max_daily_transfer: 1000000,
      max_monthly_transfer: 5000000,
      active_user: false,
      active_wallet: false,
      max_balance: 10000000,
      max_debt: 250000,
      daily_transfer_balance: "",
      has_card_recharge: false,
      has_card_issuing: false,
      has_external_orders: false,
      sim_card_number: "",
      pos_type_id: null,
      pos_field_id: null,
      j_group_id: null,
    },
  },
  poses: {
    loading: false,
    data: [],
    total: 0,
  },
  posesList: {
    loading: false,
    data: [],
    total: 0,
  },
  lastOtp: {
    dialog: false,
    data: [],
  },
  mapData: {
    data: [],
    dialog: false,
  },
};

export const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.pos.dialog = !state.pos.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.pos.data = payload.data.data;
      state.pos.total = payload.data.total;
    },
    setDataList: (state, { payload }) => {
      state.posesList.data = payload.data.data;
    },
    setLastOptDialog: (state, action) => {
      state.lastOtp.dialog = !state.lastOtp.dialog;
    },
    setLastOpt: (state, { payload }) => {
      state.lastOtp.data = payload;
    },
    setById: (state, { payload }) => {
      // state.pos.form = payload
      state.pos.form.id = payload.id;
      state.pos.form.name = payload.name;
      state.pos.form.password = payload.password;
      state.pos.form.email = payload.email;
      state.pos.form.active_user = payload.active_user;
      state.pos.form.active_wallet = payload.active_wallet;
      state.pos.form.address = payload.address;
      state.pos.form.owner = payload.owner;
      state.pos.form.mobile = payload.mobile;
      state.pos.form.group_id = payload.group;
      state.pos.form.rep_id = payload.representative;
      state.pos.form.can_transfer = payload.can_transfer;
      state.pos.form.province_id = payload.province;
      state.pos.form.city_id = payload.city;
      state.pos.form.wage = payload.wage;
      state.pos.form.max_daily_transfer = payload.max_daily_transfer;
      state.pos.form.max_monthly_transfer = payload.max_monthly_transfer;
      state.pos.form.user = payload.user;
      state.pos.form.max_debt = payload.wallet.max_debt;
      state.pos.form.max_balance = payload.wallet.max_balance;
      state.pos.form.daily_transfer_balance = payload.daily_transfer_balance;
      state.pos.form.is_approved = payload.is_approved;
      state.pos.form.latitude = payload.latitude;
      state.pos.form.longitude = payload.longitude;
      state.pos.form.has_card_issuing = payload.has_card_issuing;
      state.pos.form.has_card_recharge = payload.has_card_recharge;
      state.pos.form.has_external_orders = payload.has_external_orders;
      state.pos.form.sim_card_number = payload.sim_card_number;
      state.pos.form.pos_type_id = payload.pos_type_id;
      state.pos.form.pos_field_id = payload.pos_field_id;
      state.pos.form.j_group_id = payload.j_group_id;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.pos.form = initialState.pos.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setMapDataDialog: (state, { payload }) => {
      state.mapData.dialog = !state.mapData.dialog;
      if (payload) {
        state.mapData.data = payload;
      } else {
        state.mapData.data = [];
      }
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
  setLastOpt,
  setLastOptDialog,
  setMapDataDialog,
} = posSlice.actions;

export default posSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await posApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByUserId = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.getByUserId(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    await posApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pos"));
    dispatch(setDialog());
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update =
  (id, data, _SearchBody, isNeedReload, from, jOrderSearchBody, jOrderId) =>
    async (dispatch) => {
      try {
        dispatch(setLoading("pos"));
        await posApi.update(id, data);
        dispatch(
          showNotification({
            message: "تم التعديل بنجاح",
            type: "success",
          })
        );
        dispatch(setLoading("pos"));
        dispatch(setDialog());
        if (isNeedReload) {
          dispatch(getAll(_SearchBody));
        }
        if (from === "JOrderDetails") {
          dispatch(JOrder.getOrderDetails(jOrderId));
        }
        if (from === "JOrders") {
          dispatch(JOrder.getAll(jOrderSearchBody));
        }
      } catch (err) {
        dispatch(setLoading("pos"));
        dispatch(
          showNotification({
            message: err.response.data.errors[0],
            type: "error",
          })
        );
        throw new Error(err);
      }
    };

const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `pos.xlsx`;
    a.click();
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getLastOtp = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("pos"));
    const res = await posApi.getLastOpt(id);
    dispatch(setLastOpt(res.data.data[0]));
    dispatch(setLastOptDialog());
    dispatch(setLoading("pos"));
  } catch (err) {
    dispatch(setLoading("pos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const PointOfSales = {
  getAll,
  exportExcel,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  getLastOtp,
  getByUserId,
};
