import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  FormControlLabel,
  Switch,
  LinearProgress,
  Checkbox,
  DialogActions,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import FilePondInput from "../../../../helpers/FilePondInput";
import { NumericFormat } from "react-number-format";
import {
  JItems,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/itemsSlice";
import { SelectJSubCategory } from "../../../../components/Selectors/jomla/SelectJSubCategory";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { toNumber } from "../../../../helpers/toNumber";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JItemsForm = () => {
  const dispatch = useDispatch();

  const _JItems = useSelector((state) => state.JItems.JItem);
  const _SearchBody = useSelector((state) => state.JItems.searchBody);
  const _JSubCategory = useSelector(
    (state) => state.JSubCategories.JSubCategoriesList.data
  );
  const _Group = useSelector((state) => state.jGroup.jGroupList);

  const [loading, setLoading] = useState(false);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [attachmentsData, setAttachmentsData] = useState(
    _JItems.form.attachments ?? []
  );
  const [groups, setGroups] = useState([]);

  const isAdd = _JItems.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _JItems.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    const cleanedData = groups.filter((obj) => {
      const price = parseFloat(obj.price);
      return !isNaN(price) && isFinite(price);
    });
    const formData = new FormData();
    if (!isAdd) formData.append("id", data.id);
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("j_groups", JSON.stringify(cleanedData));
    formData.append("name", data.name);
    formData.append("j_sub_category_id", data.j_sub_category_id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("discount", data.discount ? data.discount : 0);
    formData.append("is_hidden", data.is_hidden ? 1 : 0);
    formData.append("is_active", data.is_active ? 1 : 0);
    formData.append("is_no_stock", data.is_no_stock ? 1 : 0);
    if (isAdd) {
      formData.append("sort_no", data.sort_no);
    }
    if (data.attachments && data.attachments[0].name) {
      data.attachments.forEach((file, index) => {
        if (file !== null && file !== undefined) {
          formData.append(`attachments[]`, file, file.name);
        }
      });
    }
    if (isAdd) {
      dispatch(JItems.create(formData, _SearchBody))
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    } else {
      dispatch(JItems.update(data.id, formData, _SearchBody))
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    reset({ ..._JItems.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JItems.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JItems.deleteAttachment(dialogData.id, _SearchBody)).then((x) => {
      if (x.status === 200) {
        setAttachmentsData(
          attachmentsData.filter((x) => x.id !== dialogData.id)
        );
      }
    });
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (!isAdd) setGroups(_JItems.form?.j_item_group);
  }, [isAdd]);

  const isCheck = (j_group_id) => {
    return groups.filter((x) => x.j_group_id == j_group_id).length > 0;
  };
  const getGroupPrice = (j_group_id) => {
    return groups.filter((x) => x.j_group_id == j_group_id).length > 0
      ? groups.filter((x) => x.j_group_id == j_group_id)[0].price
      : "";
  };
  const handleChecked = (e, j_group_id) => {
    var check = e.target.checked;
    if (check) setGroups([...groups, { j_group_id: j_group_id, price: 0 }]);
    else {
      const newGroups = groups.filter((x) => x.j_group_id != j_group_id);
      setGroups(newGroups);
    }
  };

  const handlePriceChange = (e, j_group_id) => {
    const newGroups = groups.filter((x) => x.j_group_id != j_group_id);
    newGroups.push({ j_group_id: j_group_id, price: toNumber(e.target.value) });
    setGroups(newGroups);
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_JItems.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <ConfirmationDialog
        open={openConfirm}
        title="تاكيد"
        message="هل انت متاكد من حذف الصورة"
        name={""}
        confirmText="حذف"
        cancelText="الغاء"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JItems.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            {isAdd ? null : (
              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Controller
                    name="j_sub_category_name"
                    control={control}
                    render={({ field }) => (
                      <Typography>
                        الصفحة الثانوية: {field.value ? field.value : ""}
                      </Typography>
                    )}
                  />
                  <Controller
                    name="j_sub_category_logo"
                    control={control}
                    render={({ field }) => (
                      <Box
                        component="img"
                        src={field.value}
                        sx={{ width: 200 }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                    inputProps={{ maxLength: 250 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={_JItems.form.is_transfer ? 6 : 12}>
              <Controller
                name="j_sub_category_id"
                control={control}
                render={({ field }) => (
                  <SelectJSubCategory
                    required
                    onChange={(e, newValue) => {
                      setValue(
                        "j_sub_category_id",
                        newValue ? newValue.id : null
                      );
                    }}
                    value={
                      _JSubCategory.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            {isAdd ? null : (
              <Grid item xs={12} md={12}>
                <Controller
                  name="sort_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="تسلسل المنتج"
                      fullWidth
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue("price", toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="السعر"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الخصم"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider textAlign="center" sx={{ marginTop: 1 }}>
                المجموعات
              </Divider>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="j_item_group"
                control={control}
                render={({ field }) =>
                  _Group ? (
                    <Grid container spacing={1}>
                      {_Group.map((group, index) => (
                        <Grid item xs={3} md={3} key={index}>
                          <Stack>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheck(group.id)}
                                  onChange={(e) => handleChecked(e, group.id)}
                                />
                              }
                              label={group.name}
                            />

                            <Box sx={{ marginBottom: 2 }}>
                              <NumericFormat
                                thousandSeparator
                                customInput={TextField}
                                disabled={!isCheck(group.id)}
                                required
                                value={getGroupPrice(group.id)}
                                onChange={(e) => handlePriceChange(e, group.id)}
                                valueIsNumericString={true}
                                allowNegative={false}
                                fullWidth
                                label="السعر"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      IQD
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  getGroupPrice(group.id) &&
                                  watch("buying_price") >
                                  getGroupPrice(group.id)
                                }
                                helperText={
                                  getGroupPrice(group.id) &&
                                    watch("buying_price") >
                                    getGroupPrice(group.id)
                                    ? `سعر البيع يجب ان يكون اعلى من سعر الشراء  (${watch(
                                      "buying_price"
                                    )} IQD)`
                                    : null
                                }
                              />
                            </Box>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <CircularProgress color="inherit" size={20} />
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_hidden"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_hidden", e.target.checked);
                        }}
                      />
                    }
                    label="اخفاء المنتج !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_active", e.target.checked);
                        }}
                      />
                    }
                    label="تفعيل المنتج !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_no_stock"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_no_stock", e.target.checked);
                        }}
                      />
                    }
                    label="قيمة لا مخزنية"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="attachments"
                control={control}
                render={({ field }) => (
                  <Box>
                    {isAdd ? null : (
                      <Grid container spacing={2}>
                        {attachmentsData?.map((file, index) => (
                          <Grid item key={index} xs={4}>
                            <Stack spacing={2} alignItems="center">
                              <Box
                                component="img"
                                src={file?.path}
                                sx={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                }}
                              />
                              <Button
                                sx={{ width: "50%" }}
                                variant="contained"
                                color="error"
                                onClick={() => handleDeleteClick(file)}
                              >
                                حذف
                              </Button>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <FilePondInput multiple field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading ? true : false}
          endIcon={loading ? <CircularProgress size={25} /> : null}
          onClick={handleSubmit(onSubmit)}
        >
          حفظ
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            dispatch(setDialog());
          }}
        >
          إلغاء
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JItemsForm;
