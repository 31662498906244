import user from "./endpoints/user";
import category from "./endpoints/category";
import subCategory from "./endpoints/subCategory";
import pos from "./endpoints/POS";
import group from "./endpoints/group";
import provinces from "./endpoints/provinces";
import banner from "./endpoints/banner";
import supplier from "./endpoints/supplier";
import items from "./endpoints/items";
import notification from "./endpoints/notification";
import pins from "./endpoints/pins";
import operation from "./endpoints/operation";
import wallet from "./endpoints/wallet";
import requestPoints from "./endpoints/request-points";
import orders from "./endpoints/orders";
import reports from "./endpoints/reports";
import roles from "./endpoints/roles";
import statistic from "./endpoints/statistic";
import expense from "./endpoints/expense";
import expenseTypes from "./endpoints/expenseTypes";
import team from "./endpoints/team";
import product from "./endpoints/products";
import productSupplier from "./endpoints/productSupplier";
import productsOrders from "./endpoints/productsOrders";
import externalRevenue from "./endpoints/externalRevenue";
import simCard from "./endpoints/simCard";
import posDiscount from "./endpoints/posDiscount";
import operationTransfer from "./endpoints/operationTransfer";
import transferBalance from "./endpoints/transferBalance";
import tickets from "./endpoints/tickets";
import projects from "./endpoints/projects";
import cards from "./endpoints/cards";
import orderRechargeCards from "./endpoints/orderRechargeCards";
import issuedCard from "./endpoints/issuedCard";
import investors from "./endpoints/investors";
import epaymentTransactions from "./endpoints/ePaymnetTransactions";
import jCategory from "./endpoints/jomla/category";
import jSubCategory from "./endpoints/jomla/subCategory";
import JItems from "./endpoints/jomla/items";
import jSupplier from "./endpoints/jomla/supplier";
import jInvoice from "./endpoints/jomla/invoice";
import jTransaction from "./endpoints/jomla/transactions";
import jOrder from "./endpoints/jomla/order";
import externalOrder from "./endpoints/posDashboard/externalOrder";
import jClearance from "./endpoints/jomla/clearance";
import jReport from "./endpoints/jomla/reports";
import jSafe from "./endpoints/jomla/safe";
import nonePos from "./endpoints/nonePos";
import jPos from "./endpoints/jomla/pos";
import jGroup from "./endpoints/jomla/jGroup";
import service from "./endpoints/service";
import settings from "./endpoints/settings";
import posType from "./endpoints/posType";
import posField from "./endpoints/posField";

const repositories = {
  user,
  category,
  subCategory,
  pos,
  group,
  provinces,
  banner,
  supplier,
  items,
  notification,
  pins,
  operation,
  wallet,
  requestPoints,
  orders,
  reports,
  roles,
  projects,
  statistic,
  expense,
  expenseTypes,
  team,
  tickets,
  cards,
  product,
  productSupplier,
  productsOrders,
  externalRevenue,
  externalOrder,
  simCard,
  posDiscount,
  operationTransfer,
  transferBalance,
  orderRechargeCards,
  issuedCard,
  investors,
  epaymentTransactions,
  nonePos,
  service,
  settings,
  posType,
  posField,

  //-----------------------------|| wholeSale ||----------------------------//

  jCategory,
  jSubCategory,
  JItems,
  jSupplier,
  jInvoice,
  jTransaction,
  jOrder,
  jClearance,
  jReport,
  jSafe,
  jPos,
  jGroup,
};

export const factory = {
  get: (name) => repositories[name],
};
